<template>
  <div>
    <div class="cooperation">
      <div class="cooperation_1">
        <div class="cooperation_2">
          <div>
            <el-image :src="require('../assets/cooperation1.png')"></el-image>
          </div>
          <div class="cooperation_p">
               <p>渠道申请表</p>
          </div>
          <div class="cooperation_f">
             
            <el-form
              :label-position="labelPosition"
              label-width="80px"
              :model="formLabelAlign"
              :rules="rules"
              action="https://formspree.io/f/xpzbqelw" method="post" id="myForm"
            >
              <el-form-item label="公司名称">
                <el-input v-model="formLabelAlign.company" placeholder="公司名称" hide-required-asterisk="true" name="公司名称"></el-input>
              </el-form-item>
              <el-form-item label="联系人"  prop="name">
                <el-input v-model="formLabelAlign.name" placeholder="姓名与职务" name="联系人"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="phone">
                <el-input v-model="formLabelAlign.phone" placeholder="请输入号码" name="手机号码"></el-input>
              </el-form-item>
              <el-form-item label="你的邮箱" prop="email">
                <el-input v-model="formLabelAlign.email" placeholder="请输入邮箱地址" name="邮箱地址"></el-input>
              </el-form-item>
              <el-form-item label="合作需求">
                <el-input type="textarea" autosize v-model="formLabelAlign.text" placeholder="请简单描述合作需求" name="合作需求"></el-input>
              </el-form-item>
             
            </el-form>
          </div>
           <div class="cooperation_b">
                  <el-button @click="submitForm" type="primary">提交</el-button>
              </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelPosition: "top",
      formLabelAlign: {
        company:"",
        name: "",
        phone: "",
        email: "",  
        text:"",
      },
      rules:{ 
          name:[
              {required:true,message:'请输入你的姓名',trigger:'blur'},
     
          ],
          phone: [
            { required: true, message: '请输入正确手机号码', trigger: 'blur' },
            { min: 11, max: 11, message: '手机号码格式不正确', trigger: 'blur' }
          ],
          email:[
              
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
    
          ]
          }  
    };
  },
  methods:{
      submitForm() {
      var obj = $("#myForm").serialize(); //获取id为content的form表单里面的内容,name值均为formspree提供不能更改
      $.ajax({
        type: "post", //必须post请求
        url: "https://formspree.io/f/xpzbqelw",
        async: true,
        data: obj,
        dataType: "json",
        success: (res) => {
          this.$refs.formLabelAlign.resetFields();
          this.$message({
            message: "邮件已发送!!!",
            type: "success",
          });
        },
        error: (res) => {
          this.$message.error("未知错误,发送失败");
        },
      });
    },
  }
};
</script>

<style>

/* 标签栏 */
.a1 .a2 .tag_1:hover{
     cursor: pointer;
}
.a1 .a2 .tag_2:hover{
    color: skyblue;
}

/* ,, */
.cooperation {
  display: flex;
  justify-content: center;
}
.cooperation .cooperation_1 {
  width: 1200px;
}
.cooperation .cooperation_1 .cooperation_p{
    text-align: center;
    
    
}
/* 表单 */


.cooperation .cooperation_1 .cooperation_b{
    width: 50px;
    margin: auto;
    margin-bottom: 20px;
}
</style>
